import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";
import PromoCodesApiService from "@/core/services/api.service.promo.codes";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "promo-code",
    data() {
        return {
            valid: false,
            isSaving: false,
            promoCodeId: null,
            promoCodeApiInitiated: false,
            shopToAdd: null,
            shops: [],
            shopsAreLoading: false,
            searchShops: "",
            addShopsDisable: false,
            promoCode: {
                name: null,
                description: null,
                quantity: null,
                price: null,
                validUntil: null,
                shops: []
            },
            shopsTableHeaders: [
                {text: 'id', value: "shopId", sortable: false},
                {text: this.$i18n.t("commonTable.shopName"), value: "shopName", sortable: false},
                {text: this.$i18n.t("common.delete"), value: "delete", sortable: false}
            ],
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    async created() {
        await PromoCodesApiService.init();
        this.promoCodeApiInitiated = true;
        this.promoCodeId = this.$route.params.promoCodeId;
        let title =
            this.promoCodeId != null
                ? "Promo code details"
                : "Insert new promo code";
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        if (this.promoCodeId != null) {
            PromoCodesApiService.get(``, `${this.promoCodeId}`)
                .then(response => {
                    this.$log.debug("Promo code: ", response.data);
                    this.promoCode = response.data.result;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
        this.loadShopsDropDown();
    },

    components: {
        ConfirmDialog
    },
    methods: {
        async delShopFromPromoCode(item) {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete shop " + item.shopName +
                    " for this promo code?"
                )
            ) {
                this.deleteShopFromList(item);
            }
        },
        deleteShopFromList(item) {
            this.$log.debug("Shop to be deleted: " + item);
            const newArray = this.promoCode.shops.filter(obj => item.shopId !== obj.shopId);
            this.promoCode.shops = newArray;
            this.$log.debug("new array: " + newArray);
        },
        addShopToPromoCode() {
            this.$log.debug("shopToAdd: " + this.shopToAdd);
            this.$log.debug("id: " + this.shopToAdd['id']);
            this.$log.debug("name: " + this.shopToAdd['name']);
            this.addShopsDisable = true;
            let shopToAdd = {
                shopId: this.shopToAdd['id'],
                shopName: this.shopToAdd['name']
            }
            this.$log.debug("shopId: " + shopToAdd['shopId']);
            this.$log.debug("shopName: " + shopToAdd['shopName']);
            var index = this.promoCode.shops.findIndex(x => x.shopId === shopToAdd['shopId']);
            index === -1 ? this.promoCode.shops.push(shopToAdd) : console.log("object already exists")
            this.$log.debug("new array: " + this.promoCode.shops);
            this.addShopsDisable = false;
        },
        loadShopsDropDown() {
            // Items have already been loaded
            if (this.shops.length > 0) return;

            // Items have already been requested
            if (this.shopsAreLoading) return;

            this.shopsAreLoading = true;
            ApiService.query("api/shops/dropdown-list")
                .then(res => {
                    this.shops = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.shopsAreLoading = false));
        },
        previousState() {
            this.$router.go(-1);
        },
        async validateAndSave() {
            if (this.$refs.form.validate()) {
                this.savePromoCode(this.promoCode);
            }
        },

        savePromoCode(promoCode) {
            this.isSaving = true;
            this.loading = true;
            if (promoCode.id != null) {

                //Don't forward these for put operation
                delete promoCode.expired;

                promoCode.expirationDate = promoCode.validUntil;
                delete promoCode.validUntil;

                PromoCodesApiService.put(`/${promoCode.id}`, promoCode)
                    .then(response => {
                        this.$log.debug("Promo code updated: " + response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            } else {
                promoCode.expirationDate = promoCode.validUntil;
                delete promoCode.validUntil;
                PromoCodesApiService.post(``, promoCode)
                    .then(response => {
                        this.$log.debug("Promo code created: " + response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState();
                    });
            }
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        ...mapGetters(["currentUser"]),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        },
        expirationDateFormatted: {
            get() {
                if (this.promoCode.validUntil == null || this.promoCode.validUntil === "")
                    return null;
                return moment
                    .utc(this.promoCode.validUntil)
                    .local()
                    .format("yyyy-MM-DD HH:mm:ss");
            },
            set(value) {
                this.promoCode.validUntil = value;
            }
        },
    }
};
